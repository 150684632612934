<template>
  <table-view
    :total="total"
    :query-info.sync="queryInfo"
    v-loading="loading"
    @reload-table="renderTable"
  >
    <template #header>
      <view-search-form
        :query-info.sync="queryInfo"
        :tool-list="['keyword']"
        :show-clear-btn="false"
        @on-search="renderTable(1)"
      />
      <div class="header-button">
        <el-button type="primary" size="mini" @click="showDialog()">新增应用版本</el-button>
      </div>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="50" fixed />
      <el-table-column prop="appName" label="应用名称" fixed width="120" />
      <el-table-column label="类型" width="78">
        <template v-slot="{row}">
          {{ appTypeList[row.type].label }}
        </template>
      </el-table-column>
      <el-table-column prop="version" label="版本" width="80">
        <template v-slot="{row}">
          <el-tag v-if="row.status" type="success">{{ row.version }}</el-tag>
          <el-tag v-else type="info">{{ row.version }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="note" label="更新日志" width="300" />
      <el-table-column prop="remarks" label="备注" width="200" />
      <el-table-column prop="createTime" label="创建时间" min-width="160" />
      <el-table-column prop="url" label="下载路径" min-width="330" />
      <el-table-column label="状态" fixed="right" width="140">
        <template v-slot="{row}">
          <el-switch
            v-model="row.status"
            active-color="var(--color-success)"
            @change="switchAppStatus($event, row)"
            active-text="启用"
            inactive-text="禁用" />
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" fixed="right">
        <template v-slot="{row}">
          <el-button type="primary" size="mini" @click="showDialog(row.id)">编辑</el-button>
          <el-button size="mini" type="danger" @click="del(deleteVersion,row.id,`${row.appName}【${row.version}】`)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--  新增/编辑 dialog  -->
    <appVersion ref="dialogRef" :app-type-list="appTypeList" :visible.sync="visibleDialog" v-if="visibleDialog"
                @on-close="renderTable" />
  </table-view>
</template>

<script>
import appVersion from './dialog/addVersion.vue'
import { getVersionList, deleteVersion, addVersion } from '@/api/version'
import tableView from '@/vue/mixins/table-view'

export default {
  components: {
    appVersion
  },
  mixins: [tableView],
  data () {
    return {
      queryInfo: {
        grade: new Date().getFullYear(),
        collegeId: null,
        majorId: null,
        campusId: null
      },
      appTypeList: [
        {
          value: 0,
          label: 'Android'
        },
        {
          value: 1,
          label: 'IOS'
        }
      ]
    }
  },
  created () {
    this.renderTable(1)
  },
  methods: {
    deleteVersion, // 删除api
    // page
    renderTable (pageNum) {
      this.getTableData(getVersionList, pageNum)
    },
    // 更新状态
    switchAppStatus (val, row) {
      const _msg = val ? '启用' : '禁用'
      this.$confirm(`是否确定${_msg}该应用版本?`).then(() => {
        addVersion({ id: row.id, status: val })
          .then(() => {
            this.$message({
              message: `${_msg}成功!`,
              type: val ? 'success' : 'error'
            })
          })
          .catch(() => {
            row.status = !val
          })
          .finally(() => {
            this.renderTable()
          })
      }).catch(() => {
        row.status = !val
      })
    }
  }
}
</script>
