import request from '@/service/request'

// 获取学年列表
const getVersionList = (params) => {
  return request.post('/app/sysVersion/page', params)
}

// 添加学年
const addVersion = (params) => {
  return request.post('/app/sysVersion/save', params)
}

// 删除学年
const deleteVersion = (params) => {
  return request.post('/app/sysVersion/del/' + params)
}

// 根据id获取学年信息
const getVersionById = (params) => {
  return request.post('/app/sysVersion/getById/' + params)
}

export {
  getVersionList,
  addVersion,
  deleteVersion,
  getVersionById
}
