<template>
  <div class="app-uploader flex">
    <el-input class="flex-1" v-model="url" clearable />
    <el-upload
      ref="uploaderRef"
      accept=".ipa,.apk"
      :action="action"
      :headers="headers"
      :show-file-list="false"
      :on-success="onSuccess"
      :before-upload="beforeUpload"
      :on-remove="remove"
      :disabled="disabled">
      <el-button class="ml-1" :disabled="disabled" type="primary" icon="el-icon-upload">
        上传APP版本
      </el-button>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: 'appUploader',
  props: {
    url: String,
    width: {
      type: String,
      default: '120px'
    },
    height: {
      type: String,
      default: '120px'
    }
  },
  data () {
    return {
      headers: {
        Authorization: window.sessionStorage.getItem('token')
      },
      action: `${process.env.VUE_APP_API_URL}/app/sysVersion/upload/installer`,
      disabled: false
    }
  },
  watch: {
    url: function (newVal) {
      this.disabled = !!newVal
      this.$emit('update:url', newVal)
    }
  },
  methods: {
    // 删除图片
    remove () {
      this.$emit('update:url', '')
      if (this.$refs.uploaderRef) {
        this.$refs.uploaderRef.clearFiles()
      }
      this.disabled = false
    },
    handleSubmit () {
    },
    // 上传前
    beforeUpload (file) {
      this.disabled = true
      const fileTypes = ['apk', 'ipa']
      const fileType = file.name.split('.')[file.name.split('.').length - 1]
      const isApp = fileTypes.includes(fileType)
      const isFileName = file.name.length < 80
      const isLt500M = file.size / 1024 / 1024 < 500

      if (!isApp) {
        this.$message.error('仅支持.apk/.ipa格式文件')
        return false
      }
      if (!isLt500M) {
        this.$message.error('上传的APP不能超过 500MB!')
        return false
      }
      if (!isFileName) {
        this.$message.error('仅支持80个字以内的文件名')
        return false
      }
      return isApp && isLt500M && isFileName
    },
    // 上传成功
    onSuccess (res) {
      if (res.success) {
        this.$emit('update:url', res.data)
      } else {
        this.$message.error({ message: '上传失败!' })
      }
    }

  }
}
</script>
