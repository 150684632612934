<template>
  <el-dialog
    class="addAppVersion-dialog"
    :title="`${preTitle}应用版本`"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="720px"
    :before-close="cancel">
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      label-width="90px"
    >
      <el-form-item label="应用名称" prop="appName">
        <el-input v-model.trim="formData.appName" />
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-form-item label="应用类型" prop="appName">
            <el-radio-group v-model="formData.type" placeholder="请选择类型">
              <el-radio-button
                v-for="{label,value} in appTypeList"
                :label="value"
                :key="value">{{ label }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="应用版本" prop="version">
            <el-input v-model.trim="formData.version" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="更新日志" prop="note">
        <el-input v-model.trim="formData.note" />
      </el-form-item>
      <el-form-item label="安装包" prop="url">
        <app-uploader :url.sync="formData.url" />
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model.trim="formData.remarks" />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag"/>
      <el-button type="primary" @click="save('APP版本',addVersion)">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addVersion, getVersionById } from '@/api/version'
import dialog from '@/vue/mixins/dialog'
import AppUploader from '@/views/pages/appManage/dialog/appUploader.vue'

export default {
  components: { AppUploader },
  mixins: [dialog],
  props: {
    appTypeList: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      formData: {
        id: null,
        appName: '',
        note: '',
        remarks: '',
        status: 0,
        type: 0,
        updateTime: '',
        url: '',
        version: ''
      },
      rules: {
        appName: [
          {
            required: true,
            message: '请输入应用名称',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.limit20WordsObj
        ],
        version: [
          {
            required: true,
            message: '请输入版本信息',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.limit20WordsObj
        ],
        note: [{
          required: true,
          message: '请输入更新日志',
          trigger: 'blur'
        }, {
          max: 200,
          message: '不能超过200字',
          trigger: 'blur'
        }],
        url: [{
          required: true,
          message: '请上传APP版本',
          trigger: 'blur'
        }, {
          max: 200,
          message: '不能超过200字',
          trigger: 'blur'
        }]
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.id) {
        this.getDataById(this.id, getVersionById)
      }
    })
  },
  methods: {
    addVersion // 保存api
  }
}
</script>
