var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"total":_vm.total,"query-info":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"reload-table":_vm.renderTable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('view-search-form',{attrs:{"query-info":_vm.queryInfo,"tool-list":['keyword'],"show-clear-btn":false},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"on-search":function($event){return _vm.renderTable(1)}}}),_c('div',{staticClass:"header-button"},[_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.showDialog()}}},[_vm._v("新增应用版本")])],1)]},proxy:true}])},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"type":"index","label":"ID","width":"50","fixed":""}}),_c('el-table-column',{attrs:{"prop":"appName","label":"应用名称","fixed":"","width":"120"}}),_c('el-table-column',{attrs:{"label":"类型","width":"78"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.appTypeList[row.type].label)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"version","label":"版本","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v(_vm._s(row.version))]):_c('el-tag',{attrs:{"type":"info"}},[_vm._v(_vm._s(row.version))])]}}])}),_c('el-table-column',{attrs:{"prop":"note","label":"更新日志","width":"300"}}),_c('el-table-column',{attrs:{"prop":"remarks","label":"备注","width":"200"}}),_c('el-table-column',{attrs:{"prop":"createTime","label":"创建时间","min-width":"160"}}),_c('el-table-column',{attrs:{"prop":"url","label":"下载路径","min-width":"330"}}),_c('el-table-column',{attrs:{"label":"状态","fixed":"right","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{attrs:{"active-color":"var(--color-success)","active-text":"启用","inactive-text":"禁用"},on:{"change":function($event){return _vm.switchAppStatus($event, row)}},model:{value:(row.status),callback:function ($$v) {_vm.$set(row, "status", $$v)},expression:"row.status"}})]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"150","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.showDialog(row.id)}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.del(_vm.deleteVersion,row.id,((row.appName) + "【" + (row.version) + "】"))}}},[_vm._v(" 删除 ")])]}}])})],1),(_vm.visibleDialog)?_c('appVersion',{ref:"dialogRef",attrs:{"app-type-list":_vm.appTypeList,"visible":_vm.visibleDialog},on:{"update:visible":function($event){_vm.visibleDialog=$event},"on-close":_vm.renderTable}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }